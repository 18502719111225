import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

import Layout from "../components/layout"
import Heading from "../components/heading"

import sales from "../images/ai/sale.jpg"
import cost from "../images/ai/cost.jpg"
import support from "../images/ai/support.jpg"
import data from "../images/ai/data.svg"
import growth from "../new/grow.svg"
import supportN from "../new/support.svg"
import money from "../new/money.svg"

function IndexPage() {
  const classes = useStyles()

  return (
    <Layout siteTitle="Artificial Intelligence">
      <Heading title="Artificial Intelligence" />
      <Box pt={10} pb={18} bgcolor="#eee" className={classes.heading}>
        <Toolbar />
        <Container maxWidth="md">
          <Typography variant="h4" color="primary" align="center" gutterBottom>
            <b>Leveraging AI for your Business</b>
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center">
            A lot of companies have a huge amount of data they have collected
            over many years purely from the perspective of investigation /
            troubleshooting. However, with the advent of powerful machine
            learning algorithms and ease of access to computing capabilities
            companies now are prioritizing top pain points that they can predict
            and take actions to address them.
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center">
            But the real charm Irrespective of the industry is that companies
            are bringing together their multiple data sources to create the kind
            of data lake that will give them the power to analyse customer
            behaviour and upsell, improving revenues.
          </Typography>
        </Container>
      </Box>
      <Box py={5}>
        <Container>
          {/* Increase in Sales */}
          <Box pb={5}>
            <Grid container justify="space-evenly" spacing={5}>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <div className="img-overlay">
                  {/* <img src={sales} alt="Increase in Sales" height="300px" /> */}
                  <img src={growth} alt="Increase in Sales" height="300px" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <Box pt={10}>
                  <Typography
                    variant="h3"
                    color="primary"
                    gutterBottom
                    style={{ marginLeft: -60 }}
                  >
                    <b>Increase in Sales</b>
                  </Typography>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Sentiment analysis of products / services basis user reviews
                  </Typography>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Advances in NLP helps use and better understand customer
                      reviews patterns on products and analyze why certain
                      products are being preferred over the others.
                    </Typography>
                  </Box>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      The same can also be done to analyze brands
                    </Typography>
                  </Box>
                  <Box pt={3}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Upselling products / services
                    </Typography>
                    <Box display="flex" pt={1}>
                      <Box pr={1}>
                        <CheckCircleOutlineIcon color="textSecondary" />
                      </Box>
                      <Typography variant="subtitle1" gutterBottom>
                        Clustering algorithms are used to group your clients
                        with similar buying patterns. Based on this, products
                        are identified for upsell or selective promotions
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Improved Customer Service */}
          <Box pb={5}>
            <Grid container justify="space-evenly" spacing={5}>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <Box pt={10}>
                  <Typography
                    variant="h3"
                    color="primary"
                    gutterBottom
                    style={{ marginLeft: -60 }}
                  >
                    <b>Improved Customer Service</b>
                  </Typography>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Better recommendation and query response
                  </Typography>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      NLP Chatbots are being significantly used today to ensure
                      24X7 customer support.
                    </Typography>
                  </Box>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      70% of customer queries are handled using chatbots. They
                      can not only address concerns that customers have but can
                      also recommend and suggest product services basis customer
                      queries
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <div className="img-overlay">
                  {/* <img
                    src={support}
                    alt="Improved Customer Service"
                    height="300px"
                  /> */}
                  <img src={supportN} alt="Increase in Sales" height="300px" />
                </div>
              </Grid>
            </Grid>
          </Box>

          {/* Optimised Costs */}
          <Box pb={5}>
            <Grid container justify="space-evenly" spacing={5}>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <div className="img-overlay">
                  {/* <img src={cost} alt="Optimised Costs" height="300px" /> */}
                  <img src={money} alt="Optimised Costs" height="300px" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                container
                justify="center"
                alignItems="center"
              >
                <Box pt={10}>
                  <Typography
                    variant="h3"
                    color="primary"
                    gutterBottom
                    style={{ marginLeft: -60 }}
                  >
                    <b>Optimised Costs</b>
                  </Typography>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Preventive Maintanence
                  </Typography>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Identifying abnormalities in machine behaviour patterns
                      enabling timely machine maintenance, preventing costly
                      downtimes
                    </Typography>
                  </Box>
                </Box>
                <Box pt={3}>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Other Cost Optimization Avenues
                  </Typography>
                  <Box display="flex" pt={1}>
                    <Box pr={1}>
                      <CheckCircleOutlineIcon color="textSecondary" />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      Algorithms are helping analyse interactions between
                      multiple factors like temp, humidity, and data from many
                      other connected devices to drive significant power
                      optimizations
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* DATA  */}
      {/* <Box py={5}>
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              container
              justify="center"
              alignItems="center"
            >
              <Box pb={5}>
                <img src={data} height="300px" alt="Data" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                <Typography variant="h5" color="primary" gutterBottom>
                  DATA IS FIRST
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Most of the folks beginning their AI journey believe that ML
                  algorithm to use is the most important part. While it
                  definitely is important and the statement may be still
                  considered fair for a one time analysis the performance of the
                  Model is largely and most importantly dependent on, Your DATA
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  If you don’t have the data then you’ll need to formulate a
                  Data Collection plan based on your objectives. You can talk to
                  data scientists/Engineers for this
                </Typography>
              </Box>
              <Box pt={5}>
                <Typography variant="h5" color="primary" gutterBottom>
                  DATA PREPARATION
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  How its cleaned and prepared for modelling Typical mistakes
                  companies make Too less data Not labelled correctly to
                  meet/aligned with business objectives Outliers not handled
                  properly and so on..
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Building the required interfaces with your existing data
                  collection systems to pull data on an ongoing basis Interface
                  to action basis the algorithms classification/prediction
                  Feedback mechanism for classification/prediction errors
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({}))

export default IndexPage
